<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'所有升降机设备'" :total="query.total" />

            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>
        <div class="layoutTabaleBorder">
            <el-table :data="query.tableData" style="width: 100%">
                <el-table-column prop="lifter_id" label="升降机ID" width="200">
                </el-table-column>
                <el-table-column
                    prop="lifter_product.lifter_name"
                    label="升降机名称"
                    width="200"
                >
                </el-table-column>
                <el-table-column
                    prop="lifter_product.lifter_model"
                    label="升降机型号"
                    width="160"
                >
                </el-table-column>
                <el-table-column prop="floor" label="安装楼层" align="center">
                </el-table-column>
                <el-table-column prop="lifter_producer.name" label="生产商">
                </el-table-column>
                <el-table-column prop="Installation_address" label="安装地址">
                </el-table-column>
                <el-table-column
                    label="更多"
                    align="center"
                    fixed="right"
                    width="240"
                >
                    <template slot-scope="scope">
                        <el-button @click="tolifterInfo(scope.row.lifter_id)">详情</el-button>
                        <el-button type="primary" @click="showEditDialog(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="layoutBorder">
            <Pagination
                :total="query.total"
                :page.sync="query.currentPage"
                @pagination="getList()"
                :limit.sync="query.pageSize"
            />
        </div>

        <!-- 编辑升降机弹窗 -->
        <el-dialog title="编辑升降机" :visible.sync="dialogEditFormVisible">
            <el-form :model="editData">
                <el-form-item label="升降机名称" :label-width="'120px'">
                    <el-input v-model="editData.lifter_name" placeholder="请输入升降机名称"></el-input>
                </el-form-item>
                <el-form-item label="升降机型号" :label-width="'120px'">
                    <el-input v-model="editData.lifter_model" placeholder="请输入升降机型号"></el-input>
                </el-form-item>
                <el-form-item label="安装楼层" :label-width="'120px'">
                    <el-input v-model="editData.floor" placeholder="请输入安装楼层"></el-input>
                </el-form-item>
                <el-form-item label="安装地址" :label-width="'120px'">
                    <el-input v-model="editData.Installation_address" placeholder="请输入安装地址"></el-input>
                </el-form-item>
                <el-form-item label="生产商" :label-width="'120px'" style="width:600px">
                    <el-select v-model="editData.lifter_producer_id" placeholder="请选择生产商">
                        <el-option v-for="producer in manufacturerData" :key="producer.id" :label="producer.name" :value="producer.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditFormVisible = false">取消</el-button>
                <el-button type="primary" @click="editLifter">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { lifter_manage_query, lifter_manufacturer_manage } from '@/api/manage/operation/lifter_manage/lifter_manage.js';
import Pagination from '@/components/Pagination.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';

export default {
    name: 'Lifter',
    data() {
        return {
            formdata: {
                keyword: '',
                lifter_status: 0,
                manufacturer_id: '',
                product_id: '',
                querypage: 1,
                pagesize: 10
            },
            // 接口数据
            query: {
                tableData: [],
                currentPage: 1,
                total: 0,
                pageCount: 0,
                pageSize: 10
            },
            manufacturerData: [],
            productData: [],
            formConfig: [
                {
                    label: '关键字',
                    prop: 'keyword',
                    component: 'el-input',
                    placeholder: '请输入关键词'
                },
                {
                    label: '升降机状态',
                    prop: 'lifter_status',
                    component: 'el-select',
                    placeholder: '请选择状态',
                    clearable: true,
                    options: [
                        { label: '装配中', value: 1 },
                        { label: '待测试', value: 2 },
                        { label: '待出厂', value: 3 },
                        { label: '已回厂', value: 4 },
                        { label: '待测试"', value: 5 },
                        { label: '待出厂', value: 6 },
                        { label: '待测试"', value: 7 }
                    ]
                },
                {
                    label: '生产商',
                    prop: 'id',
                    component: 'el-select',
                    placeholder: '请选择生产商',
                    clearable: true,
                    options: []
                }
            ],
            formModel: {},
            buttons: [{ label: '查询', type: 'primary', action: 'search' }],
            dialogEditFormVisible: false, // 编辑弹窗的可见性
            editData: {} // 编辑弹窗的数据
        };
    },
    components: {
        Pagination,
        CommonHeaders
    },
    created() {
        this.getList(); // 页面加载时执行
    },
    methods: {
        // 获取接口数据的方法
        getList() {
            lifter_manage_query({
                querypage: this.query.currentPage,
                pagesize: this.query.pageSize
            }).then(res => {
                if (res.code == 200) {
                    this.query.total = res.data.total;
                    this.query.currentPage = res.data.currentPage;
                    this.query.pageCount = res.data.pageCount;
                    this.query.tableData = res.data.result;
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        duration: 5 * 1000
                    });
                }
            });
            lifter_manufacturer_manage({}).then(res => {
                if (res.code == 200) {
                    // 生产商选择器
                    this.formConfig[2].options = res.data.map(item => ({
                        label: item.name,
                        value: item.id
                    }));
                    this.manufacturerData = res.data;
                }
            });
        },
        handleButtonClick(button, formData) {
            if (button.action === 'search') {
                this.handleFormSubmit(formData);
            } else if (button.action === 'create') {
                this.addFormButton();
            }
        },
        handleFormSubmit(formData) {
            this.formdata.keyword = formData.keyword;
            this.formdata.manufacturer_id = formData.manufacturer_id;
            lifter_manage_query(this.formdata).then(res => {
                this.query.total = res.data.total;
                this.query.tableData = res.data.result;
            });
        },
        tolifterInfo(ids) {
            this.$router.push({
                name: 'lifter_Info',
                query: {
                    id: ids
                }
            });
        },
        showEditDialog(row) {
            this.editData = { ...row, lifter_producer_id: row.lifter_producer.id }; // 预填充编辑数据
            this.dialogEditFormVisible = true; // 显示编辑弹窗
        },
        // editLifter() {
        //     lifter_update(this.editData).then(res => {
        //         if (res.code == 200) {
        //             this.$message.success('编辑成功');
        //             this.dialogEditFormVisible = false;
        //             this.getList(); // 刷新列表数据
        //         } else {
        //             this.$message.error(res.msg);
        //         }
        //     });
        // }
    }
};
</script>

<style lang="scss" scoped></style>
